import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDatafactCountry from "../../../components/Chronology/Modules/ModDatafactCountry";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";

export const frontmatter = {
  title: "Día 34",
  week: "Semana 5",
  month: "abr",
  day: "16",
  monthNumber: "04",
  date: "2020-04-16",
  path: "/cronologia/semana-05#dia-16-abr",
};
const Day34 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModText>
          El Ministerio de Cultura y Deporte ha distribuido una serie de
          recomendaciones para garantizar que las
          <strong>medidas de desinfección en el patrimonio cultural</strong>
          obligadas por la crisis del Covid-19 sean compatibles con la
          protección de los bienes culturales.
        </ModText>
        <ModTwoCols
          src="/images/svg/01_abr_plan_contingencia.svg"
          alt="call center 016"
          small={false}
          inverted={true}
        >
          Las llamadas al 016 aumentan un 47,3% en la primera quincena de abril,
          y las consultas online, aumentan un 650%, en comparación con el mismo
          periodo de 2019. En lo que se refiere al servicio de atención
          emocional y psicológica vía Whatsapp, desde que se puso en
          funcionamiento el pasado 21 de marzo, ha recibido un total de 731
          consultas.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/02_abr_ganaderia.svg"
          alt="ayudas a ganaderos"
          small={false}
        >
          El Ministerio de Agricultura, Pesca y Alimentación prevé destinar 10
          millones de euros para compensar a los ganaderos de ovino y caprino
          por las dificultades que atraviesan como consecuencia de la crisis
          sanitaria.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/1004-grupo-de-trabajo.svg"
          alt="plan inversion industrial"
          small={true}
          inverted={true}
        >
          Ministerio de Industria, Comercio y Turismo ha hecho una selección
          provisional de 192 proyectos para la adjudicación del
          <strong>Plan Reindus 2019</strong>, el Programa de Apoyo a la
          Inversión Industrial Productiva 2019.
        </ModTwoCols>
        <ModText>
          La resolución definitiva se aprobará una vez finalizado el estado de
          alarma decretado por el Gobierno.
        </ModText>
        <ModTwoCols
          src="/images/svg/0804-digital-process.svg"
          alt="NIE electrónico"
          small={false}
        >
          Política Territorial y Función Pública y el Consejo General de la
          Abogacía han firmado un convenio para ampliar los trámites de
          extranjería por vía electrónica. El objetivo de este convenio es
          evitar, en un futuro, desplazamientos y largas esperas de ciudadanos
          extranjeros en las oficinas de la Administración.
        </ModTwoCols>
        <ModDashedLine />
        <ModDataEu fecha={"2020-04-16"} />
        <ModDatafactCountry
          fecha={"2020-04-16"}
          country="us"
          data="casosConfirmados"
        />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 5.183 nuevos positivos y 551 personas fallecidas.
          Permanecen hospitalizadas 76.752 personas, 631 más que el día
          anterior, y 3.944 reciben el alta. El número de casos ingresados en la
          UCI asciende a 7.916, lo que supone un aumento de 45 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,5 % y la tasa de recuperados del 40,9 %.
        </ModText>
        <ModText>
          Desde el inicio de la crisis y hasta el día 13 de abril, la cifra
          total de PCR realizadas en España ha sido de 930.230.
        </ModText>
        <ModText>
          Esto se ha traducido en un{" "}
          <strong>aumento de las PCR diarias realizadas</strong>, que han pasado
          de 20.000 a 47.000, según el consumo estimado que han proporcionado
          las CCAA.
        </ModText>
        <ModText>
          Extermadura permite analizar los efectos de estresar el sistema
          sanitario. Cáceres, con mayor número de casos, mantiene una tendencia
          plana en los casos hospitalizados, mientras que Badajoz muestra
          relajación.
        </ModText>
        <ModGraph
          name="16-04-Analisis-ES-EX"
          alt="graficos confirmados y hospitalizados por covid-19 en extremadura"
        />
        <ModTwoCols
          src="/images/svg/18_mar_test_casa.svg"
          alt="Ensayos clinicos autorizados"
          small={false}
        >
          Respecto a los avances en investigación científica, la Agencia
          Española de Medicamentos y Productos Sanitarios (AEMPS) ha{" "}
          <strong>autorizado 32 ensayos clínicos</strong> con medicamentos para
          tratar a más de <strong>26.000 personas</strong> en diferentes
          hospitales de todo el territorio.
        </ModTwoCols>
        <ModText>
          Además, la AEMPS forma parte del ensayo "Solidaridad" de la OMS, que
          en todo el mundo tratará a más de 100.000 pacientes.
        </ModText>
        <ModTwoCols
          src="/images/svg/1004-government-digital-money.svg"
          alt="proyectos"
          small={true}
          inverted={true}
        >
          A ello se une que, hasta el momento, ya se han recibido más de{" "}
          <strong>1.300 proyectos</strong> que están siendo evaluados para
          acceder al Fondo COVID19.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/16_abr_biotecnologia.svg"
          alt="Investigación coronavirus"
          small={false}
        >
          Asimismo, el <strong>Centro Nacional de Biotecnología</strong>, del
          Consejo Superior de Investigaciones Científicas, trabaja en dos
          proyectos que ya están en el listado de{" "}
          <strong>candidatos a vacunas contra el COVID-19</strong> de la
          Organización Mundial de la Salud.
        </ModTwoCols>
        <ModText>
          El Ministerio de Sanidad, por su parte, ha impulsado una orden
          ministerial por la que establece el inicio de plazos para realizar las
          evaluaciones y la fecha final de residencia o de año formativo, de los
          profesionales sanitarios de formación sanitaria especializada.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day34;
